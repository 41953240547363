<template>
    <div class="mt-2">
        <h5 class="text-uppercase mt-0 bg-light p-2">
            Video
        </h5>
        <div class="col-12 form-group">
            <label for="link">Link</label>
            <input
                id="link"
                v-model="video.link"
                type="text"
                class="form-control"
                @input="emitVideo"
            />
        </div>
        <media-chooser
            v-model="video.thumbnail"
            label="Thumbnail"
            class="col-12"
            @input="emitVideo"
        />
    </div>
</template>

<script>
import MediaChooser from '@components/media/MediaChooser';

export default {
    components: {
        MediaChooser
    },

    props: {
        value: {
            type: Object,
            required: true,
            default: () => ({})
        }
    },

    data() {
        return {
            video: {}
        };
    },

    created() {
        this.video = { ...this.value };
    },

    methods: {
        emitVideo() {
            this.$emit('input', this.video);
        }
    }
};
</script>
