<template>
    <layout>
        <were-changes-alert
            :were-changes="wereChanges"
            @save="onSaveColorButtonClick"
        />
        <page-header :title="pageTitle" :items="breadcrumbsItems" />

        <div class="mb-3">
            <button
                type="button"
                class="btn w-sm btn-success waves-effect waves-light"
                @click="onSaveColorButtonClick"
            >
                Save
            </button>
        </div>

        <div class="row card-box">
            <div class="col-6 form-group mb-3">
                <label>Color Name<span class="text-danger">*</span></label>
                <input
                    v-model="color.name"
                    type="text"
                    class="form-control"
                    placeholder="Color name"
                    required
                />
            </div>

            <div class="col-6 form-group mb-3">
                <label>Color Hex</label>
                <color-picker
                    :key="color.id"
                    v-model="color.hex"
                    :color="color.hex"
                />
            </div>

            <media-chooser
                :key="color.id"
                v-model="color.images"
                multiple-mode
                recommended-size="830x300"
                label="Images"
                class="col-12"
            />

            <product-video
                :key="`${color.id}_v`"
                v-model="color.video"
                class="col-12"
            />
        </div>
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import appConfig from '@src/app.config';
import MediaChooser from '@components/media/MediaChooser';
import ColorPicker from '@components/elements/ColorPicker';
import ProductVideo from '@components/products/ProductVideo';
import WereChangesAlert from '@components/alerts/WereChangesAlert';

export default {
    page: {
        title: 'Bundle Details',
        meta: [{ name: 'description', content: appConfig.description }]
    },

    components: {
        ColorPicker,
        MediaChooser,
        ProductVideo,
        WereChangesAlert
    },

    props: {
        bundleId: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: false,
            default: null
        }
    },

    data() {
        return {
            pageTitle: `BUNDLE COLOR DETAIL`,
            breadcrumbsItems: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Bundle List',
                    to: '/bundles'
                }
            ],
            color: {
                images: [],
                video: {
                    thumbnail: {}
                },
                no_removable_inserts_images: []
            },
            initValueStringify: '',
            editMode: false
        };
    },

    computed: {
        wereChanges() {
            if (!this.color || !this.editMode) {
                return false;
            }

            return JSON.stringify(this.color) !== this.initValueStringify;
        }
    },

    async mounted() {
        this.breadcrumbsItems = [
            ...this.breadcrumbsItems,
            {
                text: 'Bundle Details',
                to: `/bundles/${this.bundleId}/edit`
            }
        ];

        if (!this.id) {
            this.breadcrumbsItems = [
                ...this.breadcrumbsItems,
                {
                    text: 'Add color'
                }
            ];

            return;
        }

        this.breadcrumbsItems = [
            ...this.breadcrumbsItems,
            {
                text: 'Edit color'
            }
        ];

        this.editMode = true;
        this.color = await this.getBundleColor(this.id);

        this.initValueStringify = JSON.stringify(this.color);
    },

    methods: {
        ...mapActions({
            getBundleColor: 'bundleColors/show',
            storeBundleColor: 'bundleColors/store',
            updateBundleColor: 'bundleColors/update'
        }),

        async onSaveColorButtonClick() {
            try {
                if (!this.editMode) {
                    const color = await this.storeBundleColor({
                        bundleId: this.bundleId,
                        color: this.color
                    });

                    this.$toaster('Color has been added!');

                    this.$router.push(
                        `/bundles/${this.bundleId}/colors/${color.id}/edit`
                    );

                    return;
                }

                this.color = await this.updateBundleColor({
                    id: this.id,
                    color: this.color
                });

                this.initValueStringify = JSON.stringify(this.color);

                this.$toaster('Bundle has been updated!');
            } catch (err) {
                console.error(err);

                this.$toasterError('Bundle update error!');
            }
        }
    }
};
</script>
